@import '../../../sass-vars.scss';

.wrap {

    background-color: $component-background;
    border-radius: 6px;
    display: flex;
    width: 750px;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    .topSection {
        display: flex;
        justify-content: space-between;
        button {
            margin-right: 1.5rem;
        }
    }
    .accordion {
        display: inline-block;
        width: 100% !important;

        .accordionTitle {
            display: flex;
            justify-content: space-between;
        }
    }
}