.pickerInput {
    font-size: 14px;
    font-weight: 100;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #b3b3b3;
    padding-left: 1rem;
    cursor: pointer;
}

.pickerInputDisabled {
    font-size: 14px;
    font-weight: 100;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #b3b3b3;
    padding-left: 1rem;
}