.wrap {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.count {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    padding: 0;

    h5 {
        margin-bottom: 0;
        padding-right: 0.5rem;
    }

    p {
        font-size: 14px;
    }
}

.canvas {
    width: 100%;
    margin-top: 20px;
}

.dates {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;

    .date {
        width: 30%;
        text-align: center;
        font-weight: bold;
    }
}

.pieCharts {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
}

.containerlegend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 30px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        .circle {
            width: 15px;
            height: 15px;
            border-radius: 10px;
        }

        p {
            margin-right: 15px;
            margin-left: 5px;
        }
    }
}