@import '../sass-vars.scss';

.wrap {
    position: fixed;
    height: 100vh;
    width: $panel-space;
    background-color: #4b7d9a;

    .btnWrap {
        display: grid;
        grid-template-columns: 1fr;

        button {
            align-self: flex-start;
            background-color: #78cdff;
            border-radius: 0px;
            color: white;
            margin: 0;

            &:hover {
                background-color: #4ea3d4;
                color: white;
            }

            &:active,
            &:focus {
                color: white;
            }
        }
    }
}