.table {
    max-width: 700px;

    .cell {
        max-width: 160px;
        word-wrap: break-word;


    }

    button {
        i {
            margin: 0 auto !important;
        }
    }
}