.wrap {
    padding: 1rem;
    width: 40%;
    background-color: #efefef;
    border-radius: 5px;
    margin-top: 2rem;

    .inputs {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
    }
}