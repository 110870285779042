.wrap {
    border-top: 1px solid rgb(187, 187, 187);
    border-left: 1px solid rgb(187, 187, 187);
    border-bottom: 1px solid rgb(187, 187, 187);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding: 1rem;
    background-color: #e2e2e2;
    display: flex;
    flex-direction: column;
    width: 300px;

    div {
        a {
            display: block !important;

            i {
                float: right !important;
            }
        }
    }
}