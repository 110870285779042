@import '../../../sass-vars.scss';

.wrap {
    width: 350px;
    background: $component-background;
    border-radius: 8px;
    padding: 20px;
    display: inline-table;
    margin-bottom: 1rem;

    h3 {
        margin: 0;
    }

    .innerWrap {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        justify-content: center;

        .topInputWrap {
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-radius: 10px;
            input {
                width: 100%;
            }

        }

        .btmInputWrap {
            .tblBtns {
                margin-bottom: 8px;
            }
        }
        
        
    }
}