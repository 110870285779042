.wrap {
    display: inline-block;
    padding: 1rem;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 10px;
    background-color: #e2e2e2;

    .innerWrap {
        display: flex;
        margin-bottom: 3rem;
        grid-gap: 3rem;
    }

    button {
        align-self: flex-start;
    }

    section {
        display: flex;
        flex-direction: column;
        width: 190px;

        .pickerInput {
            font-size: 14px;
            font-weight: 100;
            width: 100%;
            border-radius: 4px;
            border: 1px solid #b3b3b3;
            padding-left: 1rem;
            cursor: pointer;
        }

        .pickerInputDisabled {
            font-size: 14px;
            font-weight: 100;
            width: 100%;
            border-radius: 4px;
            border: 1px solid #b3b3b3;
            padding-left: 1rem;
        }
    }
}