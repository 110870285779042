.wrap {
    margin-top: 6rem;
}

.dropDownType {
    margin-top: 1rem;
    margin-bottom: 3rem;
    margin-left: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.titleContainer {
    width: 100%;
    padding-top: 2rem;

    .title {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 1rem;

        h4 {
            margin-bottom: 0;
        }
    }

    .subTitleContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        .subTitles {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 0;
            padding: 0;

            h5 {
                margin-bottom: 0;
                padding-right: 0.5rem;
            }
        }
    }

}