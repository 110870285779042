.actions {
    display: flex;
    grid-gap: 1rem;
    flex-direction: column;
    padding: 2.5rem;
    border: 1px solid grey;
    border-radius: 10px;
    background-color: beige;

    .choices {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 2rem
    }

    button {
        align-self: flex-start;
    }
}