.wrap {
    height: 100vh;
    padding-top: 3rem;
    background: #86bad8;

    .form {
        width: 400px;
        margin: 0 auto;

        h1 {
            text-align: center;
            margin-bottom: 1rem;
        }
    }
}