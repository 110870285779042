.wrap {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 10px;
    padding: 1rem;
    background-color: #e2e2e2;
    display: flex;
    flex-direction: column;

    div {
        a {
            display: block !important;

            i {
                float: right !important;
            }
        }
    }
}