.wrap {
    padding-top: 3rem;
    padding-left: 1rem;

    .mainUtilWrap {

        .top {
            display: flex;
            flex-direction: column;

            .choices {
                display: flex;
            }

            button {
                align-self: flex-start;
                margin: 1rem 0;
            }
        }
    }
}